
.app {
  position: relative;
 padding: 2%;
}
h1,h2{
  text-align: center;
  background-color:#1d1d1d;
  color:#cfcfcf;
  padding:0 10px 0 10px ;

border-radius: 10px;
}

.list {
 display:grid;

place-items: center;
margin: 0;


}


.main{

margin-bottom: 70px;

}

.center1 {
  border: 5px solid;
  position: absolute;
  top: 50%;
  transform: translate(-50%, 0);
  padding: 10px;
}

.btn1 {
cursor: pointer;
width:300px;

}
.b_icon:hover{

cursor: pointer;
border-bottom: solid 2px #c2c0c0 ;
}

.b_icon:active {
  border: solid 2px #c2c0c0 ;
  border-radius: 6px;
}

.b_icon{
  padding: 7px;
  padding-bottom: 10px;
  width: 32px;
  height: 32px;
  margin: 15px;
}

.navitem a:active{
  border: solid #c2c0c0 2px!important;
}

.img__wrap {
  cursor: pointer;
  position: relative;
 display: flex;

}


.hide{
  
  display: none;
 
 }
.img__description {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size:400;
  font-weight:bolder;
  background-color: #ffffff;
  border-radius: 7px;
 min-width: 32px;
  color: #252525;
  visibility: hidden;
  opacity: 0;

  /* transition effect. not necessary */

}
.img__wrap:hover .img__description {
  
  visibility: visible;
  opacity: 0.7;
}

.img__wrap:hover .centered {
  
  visibility: hidden;
  opacity: 0;
}



.img__wrap:hover {
  background-color:gray;

}



.floor{
  position: relative;
  text-align: center;
  color: rgb(238, 238, 238);
}

.centered {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size:400;
  font-weight:bolder;
  background-color: #636363;
  border-radius: 7px;
 min-width: 32px;
}


.products{
  margin: 20px;
  border: solid 4px darkslategray  ;
  border-radius: 10px;
  width: 300px;
    
     display: flex;
     flex-direction:column;
     align-items: center;
     
  
 }
 


.b_img{
  width: 100px;
}
.container1{
  display: grid;
  grid-template-columns: auto auto auto ;
  row-gap: 50px;
  row-gap: 50px;
  padding: 20px;


  width:fit-content;
  height: fit-content;
  }


  
.container_apart{
  display: grid;
  grid-template-columns: auto auto ;
  row-gap: 40px;

  padding: 20px;


  width:max-content;
 height: fit-content;
  }



  
  .container_dash{
    display: grid;
    grid-template-columns: auto  auto ;
    row-gap: 40px;
  
    padding: 20px;
  
  margin-top: 15%;
    width:max-content;
   height: fit-content;
    }
  


.elements{
  color: #1a1a1a;
  font-weight: bolder;
  background-color: rgba(180, 163, 86, 0.596);
  border-radius: 5px;
  text-align: center;
width:fit-content;
  
}

.sub_element:hover{
cursor: pointer;
}


.e_content{
  color: #d1d1d1;
  font-weight: bold;
  font-size: large;
  background-color: rgba(79, 109, 106, 0.76);
  border-radius: 5px;
  text-align: center;
max-width: 200px;
width:200px;
word-wrap: break-word;
}



.e_content1{
  color: #d1d1d1;
  font-weight: bold;
  font-size: large;
  background-color: rgba(65, 65, 65, 0.562);
  border-radius: 5px;
  text-align: center;
max-width: 200px;
  
}

.elements:hover{
  cursor: pointer;
}
  .cat{
  
  border-radius: 40px;
    cursor: pointer;
    
    background-color: #ffffff;
  text-align: center;
    opacity: 0.8;
padding: 2px;
 width: 180px;
  height: 180px;
  margin:15px;
 
    }
  
  
.cat:hover{
  cursor: pointer;
  background-color: #c2c0c0;
  opacity: 0.8;

}

li{
  word-spacing: 2px;
}


.imglink{
  background-image: url("/public/cover3.jpg");
  background-size: cover;
background-repeat: no-repeat;

border-radius: 40px;
  cursor: pointer;
  color: rgb(255, 255, 255);

 
width: 80px;
height: 80px;
margin:15px;
  }


  
  .container2{
    overflow-x: auto;
    width: 100%;
    
  }
 
  .icon{
    background-image: url("/public/door_close.png");
    background-size: cover;
  background-repeat: no-repeat;
    width:40px;
    height: 40px;
  
    border-radius: 30px;
  }


  .apartdes{
    margin: 15px;
    background-color: #78e421;
border-radius: 20px;
opacity: 0.8;
  }


  .apartdes_selled{
    margin: 15px;
    background-color: #fa2a2a;
border-radius: 20px;
opacity: 0.8;
  }

 


  .apartdes_reserved{
    margin: 15px;
    background-color: #ff8a1c;
border-radius: 20px;
opacity: 0.8;
  }



  .apartdes_selled:hover .icon{
  
    background-image: url("/public/door_open.png");
    background-size: cover;
  background-repeat: no-repeat;
  }

  .apartdes_reserved:hover .icon{
  
    background-image: url("/public/door_open.png");
    background-size: cover;
  background-repeat: no-repeat;
  }




  .apartdes_reserved:hover{
    cursor: pointer;
    border: 2px solid #999 ;
 
  }
  .apartdes_selled:hover{
    cursor: pointer;
    border: 2px solid #999 ;
  }
  .apartdes:hover{
    cursor: pointer;
    border: 2px solid #999 ;
  }

  
  .apartdes_selled:hover .title{
    background-color: #999;
  }
 
  .apartdes_reserved:hover .title{
    background-color: #999;
  }
 

  .apartdes:hover .title{
    background-color: #999;
  }
 

  .apartdes:hover .icon{
  
    background-image: url("/public/door_open.png");
    background-size: cover;
  background-repeat: no-repeat;
  }



  

  .apartdes_reserved:hover .imglink{
    background-image: url("/public/cover_zoom.jpg");
  
  background-repeat: no-repeat;
  

  border: 2px solid #999 ;

    }

  .apartdes_selled:hover .imglink{
    background-image: url("/public/cover_zoom.jpg");
  
  background-repeat: no-repeat;
  

  border: 2px solid #999 ;

    }


  .apartdes:hover .imglink{
    background-image: url("/public/cover_zoom.jpg");
  
  background-repeat: no-repeat;
  

  border: 2px solid #999 ;

    }
  

.error{
  text-align: center;
  background-color: #d12626;
  color: #ffffff;
  border-radius: 10px;
  text-decoration: none;
  margin:5px;
}

.error :hover{

font-size: x-large;

margin:10px;
}



  .title{

  text-align: center;
background-color: #2b2b2b;
color: #ffffff;
border-radius: 30px;

margin:5px;
height: 28px;
  }

a{
  text-decoration: none;
}
  

  .apartdes1{
    margin: 15px;
background-color: #fff;
border-radius: 20px;
opacity: 0.8;

  }
  .apartdes1:hover{
    cursor: pointer;
    border: 2px solid #999 ;
  }
  .apartdes1:hover .title{
    background-color: #999;
  }
 

  .apartdes1:hover .icon{
  
    background-image: url("/public/door_open.png");
    background-size: cover;
  background-repeat: no-repeat;
  }



  .apartdes1:hover .imglink{
    background-image: url("/public/cover_zoom.jpg");
  
  background-repeat: no-repeat;
  

  border: 2px solid #999 ;

    }
  



  
    .imglink2{
      background-image: url("/public/blueprint.png");
      background-position: center center;
  
    background-repeat: no-repeat;
    
    
      cursor: pointer;
      color: rgb(255, 255, 255);
  
        border-radius: 40px;
    width: 90px;
     height: 90px;
    margin:5px;
      }
    

  .apartdes1:hover  .imglink2{
    background-image: url("/public/house-plan2.png");
    background-size: cover;
  background-repeat: no-repeat;
    border: 2px solid #999 ;
  margin-left:7px ;
    width: 90px;
    height: 90px;
    
  }




  .imglink4{
    background-image: url("/public/utl_icon.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    border: 2px solid #999 ;
    cursor: pointer;
    color: rgb(255, 255, 255);

      border-radius: 40px;
  width: 90px;
   height: 90px;
  margin:5px;
    }



    



    .apartdes1:hover  .utilities{
      border-radius: 40px;

      border: 2px solid #999 ;
      width: 90px;
      height: 90px;
      
    }

  .utilities{
 
    cursor: pointer;
 
    border-radius: 30px;
   
  width: 90px;
   height: 90px;
  margin:5px;
    }




    .planimg{
 
      cursor: pointer;
   
      border-radius: 50px;
   
      height: 90px;
      width: 90px;
   

      }
    

  

.apartdes1:hover  .imglink4{
  background-image: url("/public/utl_icon_zoom.jpg");
  background-size: cover;
background-repeat: no-repeat;
  border: 2px solid #999 ;
  width: 90px;
  height: 90px;
  
}







  
.loader-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;

  z-index: 1;
}







#container{
  margin: 15px;
  padding: 5px;
  
  font-size: 22px;
}


table, th, td {
 
  text-align: center;

}






.app label{
  font-size: 22px;
}


#excel {
  width: auto;
  background-color: #00831c;
  color: white;
  border: 0;
  margin: 10px;
  padding: 10px 17px 10px 17px;
}


#pdf {
  width: auto;
  background-color: #b60000;
  color: white;
  border: 0;
  margin: 10px;
  padding: 10px 17px 10px 17px;
}






.imglink1{
  color: darkslategray;
    padding-right: 1.2%;
  }
  .icon1{
    width:50px;
    height: 50px;
    border-radius: 30px;
  }
  .icon1:hover{
   border:1px solid #999;
  }
  .username1{
    cursor: pointer;
  }
  .username1:hover{
    color:#999;
    
  }
  
  .username1:hover .icon1{
    border:1px solid #999;
    
  }
  .title1{
   color:darkslategray;
   font-size: 20px;
   font-weight: 500;
  }
  

  .bg_img{
    width: 100%;
    min-height: 100vh;
    background-image: url("/public/cover.jpg");
    background-size: cover;
    background-repeat: repeat-y;
  }
  
  
  .bg_img1{
    width: 100%;
    min-height: 100vh;
    background-image: url("/public/utl.jpg");
    background-size: cover;
    background-repeat: repeat-y;
  }



  .bg{
    width: 100%;
    min-height: 100vh;

    margin-top: 90px;
    margin-bottom: 70px;
  }



  .plan_img{
    width: 50%;
  }


  .bg_img2{
    width: 100%;
    min-height: 100vh;
    background-image: url("/public/dash.jpeg");
    background-size: cover;
    background-repeat: repeat-y;
  }
  
  .bg_img_c{
    width: 100%;
    min-height: 100vh;
    background-image: url("/public/Clients-Background.png");
    background-size: cover;
    background-repeat: repeat-x repeat-y;
  }
    

      .imglink3{
        color: white;
          padding-right: 1.2%;
        }
      .profile_i{
        width:50px;
        height: 50px;
        border-radius: 30px;
      }
      .profile_i:hover{
       border:1px solid #999;
      }
      .username3{
        cursor: pointer;
      }
      .username3:hover{
        color:#999;
        
      }     

.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  text-align: center;
}
 
.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #f44336;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}

.confirm{
  width:300px;
  height: 50px;
  margin: 10px;
  font-size: 22px;
}


.nav-link{
  color:gray;
  font-weight: bold;
  
}


.nav-link:hover{
  color:#000;
  font-size: 19px;
}

.flex{
  display: flex;
}
.excel{
  background-color: green;
}
 


a.btn {
  font-size: 22px;
  font-weight: 700;
  border:2px solid rgb(155, 155, 155);
  color: #c2c0c0;
}


a.btn:hover {

background-color:  rgb(155, 155, 155);

}

p.btn.default-btn {
  color: #f44336;
  font-weight: 700;
  text-decoration: underline;
  
}



p.btn.default-btn1 {
  color: #1ffc2a;
  font-weight: 700;
  text-decoration: underline;
  
}



.categories{
  font-size: xx-large;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
}

.utilities {
  display: block;
  margin-left: auto;
  margin-right: auto;
  
}


.con {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
width: 300px;
}




.l_cover{
  width: 100%;
  min-height: 100vh;
  background-image: url("/public/logincover.jpg");
  background-size: cover;
  background-repeat: repeat-y;
}

.center {

  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  max-width: 600px;
  height: 55%;
  min-height: 430px;
  text-align: center;
  transform: translate(-50%, -50%);
  padding: 10px;



}


.center1 {

  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: 600px;
  

  text-align: center;
  transform: translate(-50%, -50%);
  padding: 10px;

border: none;

}

.dash_item{
  
  border-radius: 40px;
    cursor: pointer;
    
    background-color: #ffffff;
  text-align: center;
    opacity: 0.8;
padding: 2px;
 width: 150px;
  height: 150px;
  margin:10px;
 
    }


.dash_item:hover{

  background-color: #999;
  opacity: 0.8;

}
  
 

    .dash_item_title{

      text-align: center;
    background-color: #2b2b2b;
    color: #ffffff;
    border-radius: 30px;
    
    
    height: 28px;
      }
    









      .Btn1 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 45px;
        height: 45px;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition-duration: .3s;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
        background-color: rgb(255, 164, 59);
      }
      
      /* plus sign */
      .sign1 {
        width: 100%;
        transition-duration: .3s;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .sign1 svg {
        width: 17px;
      }
      
      .sign1 svg path {
        fill: white;
      }
      /* text */
      .text1 {
        position: absolute;
        right: 0%;
        width: 0%;
        opacity: 0;
        color: white;
        font-size: 1.2em;
        font-weight: 600;
        transition-duration: .3s;
      }
      /* hover effect on button width */
      .Btn1:hover {
        width: 200px;
        border-radius: 40px;
        transition-duration: .3s;
      }
      
      .Btn1:hover .sign1 {
        width: 30%;
        transition-duration: .3s;
        padding-left: 20px;
      }
      /* hover effect button's text */
      .Btn1:hover .text1 {
        opacity: 1;
        width: 70%;
        transition-duration: .3s;
        padding-right: 10px;
      }
      /* button click effect*/
      .Btn1:active {
        transform: translate(2px ,2px);
      }
      
      








      .Btn2 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 45px;
        height: 45px;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition-duration: .3s;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
        background-color: rgba(59, 255, 134, 0.733);
      }
      
      /* plus sign */
      .sign2{
        width: 100%;
        transition-duration: .3s;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      
      .sign2 svg {
        width: 17px;
      }
      
      .sign2 svg path {
        fill: white;
      }
      /* text */
      .text2 {
        position: absolute;
        right: 0%;
        width: 0%;
        opacity: 0;
        color: white;
        font-size: 1.2em;
        font-weight: 600;
        transition-duration: .3s;
      }
      /* hover effect on button width */
      .Btn2:hover {
        width: 200px;
        border-radius: 40px;
        transition-duration: .3s;
      }
      
      .Btn2:hover .sign2 {
        width: 30%;
        transition-duration: .3s;
        padding-left: 20px;
      }
      /* hover effect button's text */
      .Btn2:hover .text2 {
        opacity: 1;
        width: 70%;
        transition-duration: .3s;
        padding-right: 10px;
      }
      /* button click effect*/
      .Btn2:active {
        transform: translate(2px ,2px);
      }











      



.Btn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 45px;
  height: 45px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition-duration: .3s;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.199);
  background-color: rgb(255, 65, 65);
}

/* plus sign */
.sign {
  width: 100%;
  transition-duration: .3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sign svg {
  width: 17px;
}

.sign svg path {
  fill: white;
}
/* text */
.text {
  position: absolute;
  right: 0%;
  width: 0%;
  opacity: 0;
  color: white;
  font-size: 1.2em;
  font-weight: 600;
  transition-duration: .3s;
}
/* hover effect on button width */
.Btn:hover {
  width: 125px;
  border-radius: 40px;
  transition-duration: .3s;
}

.Btn:hover .sign {
  width: 30%;
  transition-duration: .3s;
  padding-left: 20px;
}
/* hover effect button's text */
.Btn:hover .text {
  opacity: 1;
  width: 70%;
  transition-duration: .3s;
  padding-right: 10px;
}
/* button click effect*/
.Btn:active {
  transform: translate(2px ,2px);
}















.mycard {
  overflow: visible;
  width:300px;
  height:420px;
}

.content {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 300ms;
  box-shadow: 0px 0px 10px 1px #000000ee;
  border-radius: 5px;
}

.front, .back {
  background-color: #151515;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 5px;
  overflow: hidden;
}

.back {
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.back::before {
  position: absolute;
  content: ' ';
  display: block;
  width: 160px;
  height: 160%;
  background: linear-gradient(90deg, transparent, #ff9966, #ff9966, #ff9966, #ff9966, transparent);
  animation: rotation_481 5000ms infinite linear;
}

.back-content {
  position: absolute;
  width: 99%;
  height: 99%;
  background-color: #151515;
  border-radius: 5px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.mycard:hover .content {
  transform: rotateY(180deg);
}

@keyframes rotation_481 {
  0% {
    transform: rotateZ(0deg);
  }

  0% {
    transform: rotateZ(360deg);
  }
}

.front {
  transform: rotateY(180deg);
  color: white;
}

.front .front-content {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.front-content .badge {
  background-color: #00000055;
  padding: 2px 10px;
margin-top: 7px;
  margin-right: 25px;
  border-radius: 10px;
  backdrop-filter: blur(2px);
  width: fit-content;
}

.description {
  box-shadow: 0px 0px 10px 5px #cccccc88;
  width: 100%;
  padding: 10px;
  background-color: #00000099;
  backdrop-filter: blur(5px);
  border-radius: 5px;
}

.title_ {
  font-size: 20px;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}

.title_ p {
  width: 50%;
}

.card-footer {
  color: #ffffff88;
  margin-top: 5px;
  font-size: 13px;
}

.front .img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.circle {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  background-color: #ffbb66;
  position: relative;
  filter: blur(15px);
  animation: floating 2600ms infinite linear;
}

#bottom {
  background-color: #ff8866;
  left: 50px;
  top: 0px;
  width: 150px;
  height: 150px;
  animation-delay: -800ms;
}

#right {
  background-color: #ff2233;
  left: 160px;
  top: -80px;
  width: 30px;
  height: 30px;
  animation-delay: -1800ms;
}

@keyframes floating {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(0px);
  }
}


.enter {
  background: transparent;
  color: #fff;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 600;
  border: none;
  padding: 20px 30px;
  perspective: 30rem;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.308);

 width: 50%;
}

.enter::before {
  content: '';

  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 10px;
  background: linear-gradient(320deg, rgba(0, 140, 255, 0.678), rgba(128, 0, 128, 0.308));
  z-index: 1;
  transition: background 3s;
}

.enter:hover::before {
  animation: rotate 1s;
  transition: all .5s;
}

@keyframes rotate {
  0% {
    transform: rotateY(180deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}




.spinner {
  width: 80px;
  height: 80px;
  --clr: rgb(127, 207, 255);
  --clr-alpha: rgb(127, 207, 255, .1);
  animation: spinner 2s infinite linear;
  transform-style: preserve-3d;
}

.spinner > div {
  background-color: var(--clr-alpha);
  height: 100%;
  position: absolute;
  width: 100%;
  border: 5px solid var(--clr);
}

.spinner div:nth-of-type(1) {
  transform: translateZ(-40px) rotateY(180deg);
}

.spinner div:nth-of-type(2) {
  transform: rotateY(-270deg) translateX(50%);
  transform-origin: top right;
}

.spinner div:nth-of-type(3) {
  transform: rotateY(270deg) translateX(-50%);
  transform-origin: center left;
}

.spinner div:nth-of-type(4) {
  transform: rotateX(90deg) translateY(-50%);
  transform-origin: top center;
}

.spinner div:nth-of-type(5) {
  transform: rotateX(-90deg) translateY(50%);
  transform-origin: bottom center;
}

.spinner div:nth-of-type(6) {
  transform: translateZ(40px);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg) rotateX(0deg) rotateY(0deg);
  }

  50% {
    transform: rotate(180deg) rotateX(180deg) rotateY(180deg);
  }

  100% {
    transform: rotate(360deg) rotateX(360deg) rotateY(360deg);
  }
}







.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
  max-width: 1200px;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  background-color: #1a1a1a;
  color: #fff;
  border: 1px solid #333;
}

.title {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -1px;
  position: relative;
  display: flex;
  align-items: center;
  padding-right: 5px;
  padding-left: 30px;
  color: #e4e4e4;
}

.title::before {
  width: 18px;
  height: 18px;
}

.title::after {
  width: 18px;
  height: 18px;
  animation: pulse 1s linear infinite;
}

.title::before,
.title::after {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  border-radius: 50%;
  left: 4px;
  background-color: #e4e4e4
}

.message, 
.signin {
  font-size: 14.5px;
  color: rgba(255, 255, 255, 0.7);
}

.signin {
  text-align: center;
}

.signin a:hover {
  text-decoration: underline royalblue;
}

.signin a {
  color: #00bfff;
}

.flex {
  display: flex;
  width: 100%;
  gap: 6px;
}

.form label {
  position: relative;
}

.form label .input {
  background-color: #333333;
  color: #fff;
  width: 100%;
  padding: 20px 05px 05px 10px;
  outline: 0;
  border: 1px solid rgba(105, 105, 105, 0.397);
  border-radius: 10px;
}

.form label .input + span {
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  left: 10px;
  top: 0px;
  font-size: 0.9em;
  cursor: text;
  transition: 0.3s ease;
}

.form label .input:placeholder-shown + span {
  top: 12.5px;
  font-size: 0.9em;
}

.form label .input:focus + span,
.form label .input:valid + span {
  color: #00bfff;
  top: 0px;
  font-size: 0.7em;
  font-weight: 600;
}

.input {
  font-size: medium;
}

.submit {
  border: none;
  outline: none;
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 16px;
  transform: .3s ease;
  background-color: #00bfff;
}

.submit:hover {
  background-color: #00bfff96;
}

@keyframes pulse {
  from {
    transform: scale(0.9);
    opacity: 1;
  }

  to {
    transform: scale(1.8);
    opacity: 0;
  }
}


input[type="file"]:hover {
cursor: pointer;
}
input[type="file"]::file-selector-button {
  border: 1px solid rgba(105, 105, 105, 0.397);
  padding: 0.2em 0.4em;
  margin: 0.2em 0.2em;
  border-radius: 0.2em;
  background-color: #333;
  color: rgba(255, 255, 255, 0.5);
  transition: 1s;
}

input[type="file"]::file-selector-button:hover{
  border: 1px solid rgba(105, 105, 105, 0.397);
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #9c9c9c;
  transition: 1s;
  cursor: pointer;
}




.container form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.container label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}

.container  label input {
  position: absolute;
  left: -9999px;
}

.container label input:checked + span {
  background-color: #737377;;
  color: white;
}

.container label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em rgb(24, 23, 23);
}

.container label span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 99em;
  transition: 0.25s ease;
  color: rgba(255, 255, 255, 0.795);
}

.container label span:hover {
  background-color: rgba(39, 39, 39, 0.986)
}

.container label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #ffffff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em rgba(105, 105, 105, 0.397);
}



input

input{
  border: 1px solid rgba(105, 105, 105, 0.397);
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #9c9c9c;
  transition: 1s;
  cursor: pointer;
}



.form-container {
  width: 400px;
  background: linear-gradient(#1a1a1a, #1a1a1a) padding-box,
              linear-gradient(145deg, transparent 35%,#e81cff, #40c9ff) border-box;
  border: 2px solid transparent;
  padding: 32px 24px;
  font-size: 14px;
  font-family: inherit;
  color: white;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
  border-radius: 16px;
}

.form-container button:active {
  scale: 0.95;
}

.form-container .form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-container .form-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.form-container .form-group label {
  display: block;
  margin-bottom: 5px;
  color: #717171;
  font-weight: 600;
  font-size: 12px;
}

.form-container .form-group input {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  color: #fff;
  font-family: inherit;
  background-color: transparent;
  border: 1px solid #414141;
}

.form-container .form-group textarea {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  resize: none;
  color: #fff;
  height: 96px;
  border: 1px solid #414141;
  background-color: transparent;
  font-family: inherit;
}

.form-container .form-group input::placeholder {
  opacity: 0.5;
}

.form-container .form-group input:focus {
  outline: none;
  border-color: #e81cff;
}

.form-container .form-group textarea:focus {
  outline: none;
  border-color: #e81cff;
}

.form-container .form-submit-btn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  align-self: flex-start;
  font-family: inherit;
  color: #d8d8d8;
  font-weight: 600;
  width: 40%;
  background: #313131;
  border: 1px solid #414141;
  padding: 12px 16px;
  font-size: inherit;
  gap: 8px;
  margin-top: 8px;
  cursor: pointer;
  border-radius: 6px;
}

.form-container .form-submit-btn:hover {
  background: #525252;
  border-color: #fff;
}
/* The switch - the box around the slider */




input[type="file"]{
  border: 1px solid rgba(105, 105, 105, 0.397);
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #9c9c9c;
  transition: 1s;
  cursor: pointer;
}

input{
  border: 1px solid rgba(105, 105, 105, 0.397);
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  background-color: #9c9c9c;
  color: #1a1a1a;
  transition: 1s;
  cursor: pointer;
}



.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  text-align: center;
}
 
.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #383838;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #f44336;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid #999;
  font-size: 20px;
}


.delete-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.delete-svgIcon {
  width: 15px;
  transition-duration: 0.3s;
}

.delete-svgIcon path {
  fill: white;
}

.delete-button:hover {
  width: 90px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(255, 69, 69);
  align-items: center;
}

.delete-button:hover .delete-svgIcon {
  width: 20px;
  transition-duration: 0.3s;
  transform: translateY(60%);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.delete-button::before {
  display: none;
  content: "Delete";
  color: white;
  transition-duration: 0.3s;
  font-size: 2px;
}

.delete-button:hover::before {
  display: block;
  padding-right: 10px;
  font-size: 13px;
  opacity: 1;
  transform: translateY(0px);
  transition-duration: 0.3s;
}






.mouse_scroll {
	display: block;
  position: fixed;

	width: 24px;
	height: 100px;
bottom: 20%;
}


.m_scroll_arrows
{
  display: block;
  width: 5px;
  height: 5px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
   
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  margin: 0 0 3px 4px;
  
  width: 16px;
  height: 16px;
}


.unu
{
  margin-top: 1px;
}

.unu, .doi, .trei
{
    -webkit-animation: mouse-scroll 1s infinite;
    -moz-animation: mouse-scroll 1s infinite;
    animation: mouse-scroll 1s infinite;
  
}

.unu
{
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;
  
  animation-direction: alternate;
  animation-delay: alternate;
}

.doi
{
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
  
  animation-delay: .2s;
  animation-direction: alternate;
  
  margin-top: -6px;
}

.trei
{
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;
  
  animation-delay: .3s;
  animation-direction: alternate;
  
  
  margin-top: -6px;
}

.mouse {
  height: 42px;
  width: 24px;
  border-radius: 14px;
  transform: none;
  border: 2px solid white;
  top: 170px;
}

.wheel {
  height: 5px;
  width: 2px;
  display: block;
  margin: 5px auto;
  background: white;
  position: relative;
  
  height: 4px;
  width: 4px;
  border: 2px solid #fff;
  -webkit-border-radius: 8px;
          border-radius: 8px;
}

.wheel {
  -webkit-animation: mouse-wheel 0.6s linear infinite;
  -moz-animation: mouse-wheel 0.6s linear infinite;
  animation: mouse-wheel 0.6s linear infinite;
}

@-webkit-keyframes mouse-wheel{
   0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateY(6px);
    -ms-transform: translateY(6px);
    transform: translateY(6px);
  }
}
@-moz-keyframes mouse-wheel {
  0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}
@-o-keyframes mouse-wheel {

   0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}
@keyframes mouse-wheel {

   0% { top: 1px; }
  25% { top: 2px; }
  50% { top: 3px;}
  75% { top: 2px;}
  100% { top: 1px;}
}

@-webkit-keyframes mouse-scroll {

  0%   { opacity: 0;}
  50%  { opacity: .5;}
  100% { opacity: 1;}
}
@-moz-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@-o-keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@keyframes mouse-scroll {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}



.button {
  top: -20;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: white;
}

.button:hover {
  width: 140px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(181, 160, 255);
  align-items: center;
}

.button:hover .svgIcon {
  /* width: 20px; */
  transition-duration: 0.3s;
  transform: translateY(-200%);
}

.button::before {
  position: absolute;
  bottom: -20px;
  content: "Back to Top";
  color: white;
  /* transition-duration: .3s; */
  font-size: 0px;
}

.button:hover::before {
  font-size: 13px;
  opacity: 1;
  bottom: unset;
  /* transform: translateY(-30px); */
  transition-duration: 0.3s;
}
